@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Faktum';
  src:
    local('Faktum'),
    url(/fonts/Faktum.woff) format('woff');
}

/* inter-100 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  src: url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-100.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-100.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-100.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-100.woff')
      format('woff'),
    /* Modern Browsers */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-100.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-100.svg#Inter')
      format('svg'); /* Legacy iOS */
}
/* inter-200 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  src: url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-200.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-200.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-200.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-200.woff')
      format('woff'),
    /* Modern Browsers */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-200.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-200.svg#Inter')
      format('svg'); /* Legacy iOS */
}
/* inter-300 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.woff')
      format('woff'),
    /* Modern Browsers */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.svg#Inter')
      format('svg'); /* Legacy iOS */
}
/* inter-regular - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.woff')
      format('woff'),
    /* Modern Browsers */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.svg#Inter')
      format('svg'); /* Legacy iOS */
}
/* inter-500 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url('/fonts/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.woff')
      format('woff'),
    /* Modern Browsers */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.svg#Inter')
      format('svg'); /* Legacy iOS */
}
/* inter-600 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-600.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-600.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-600.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-600.woff')
      format('woff'),
    /* Modern Browsers */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-600.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-600.svg#Inter')
      format('svg'); /* Legacy iOS */
}
/* inter-700 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.woff')
      format('woff'),
    /* Modern Browsers */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.svg#Inter')
      format('svg'); /* Legacy iOS */
}
/* inter-800 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  src: url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-800.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-800.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-800.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-800.woff')
      format('woff'),
    /* Modern Browsers */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-800.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-800.svg#Inter')
      format('svg'); /* Legacy iOS */
}
/* inter-900 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  src: url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-900.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-900.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-900.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-900.woff')
      format('woff'),
    /* Modern Browsers */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-900.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-900.svg#Inter')
      format('svg'); /* Legacy iOS */
}

@layer base {
  :root {
    /* Default */
    --background: 0deg 0% 100%;

    --error: 3deg 54% 22%;
    --error-foreground: 240deg 25% 98%;

    --foreground: 222.2deg 47.4% 11.2%;

    --muted: 210deg 40% 96.1%;
    --muted-foreground: 215.4deg 16.3% 46.9%;

    --popover: 0deg 0% 100%;
    --popover-foreground: 222.2deg 47.4% 11.2%;

    --tooltip: 0deg 0% 100%;
    --tooltip-foreground: 222.2deg 47.4% 11.2%;

    --border: 214.3deg 31.8% 91.4%;
    --input: 214.3deg 31.8% 91.4%;

    --card: 0deg 0% 100%;
    --card-foreground: 222.2deg 47.4% 11.2%;

    /* Default */
    --primary: 222.2deg 47.4% 11.2%;
    /* Chill */
    /* --primary: 239deg 82% 17%; */
    /* 123 211, 48%, 28%*/
    /* --primary: 211deg 48% 28%; */
    /* purple 303deg 36%, 38% */
    /* --primary: 303deg 36% 38%; */

    --primary-foreground: 210deg 40% 98%;

    --secondary: 210deg 40% 96.1%;
    --secondary-foreground: 222.2deg 47.4% 11.2%;

    --accent: 210deg 40% 96.1%;
    --accent-foreground: 222.2deg 47.4% 11.2%;

    --destructive: 0deg 100% 50%;
    --destructive-foreground: 210deg 40% 98%;

    --ring: 215deg 20.2% 65.1%;

    --radius: 0.75rem;

    --font-sans: 'Inter';
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --tooltip: 224 71% 4%;
    --tooltip-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 1rem;

    --font-sans: 'Inter';
  }
}

@layer base {
  body {
    @apply text-foreground;
    font-feature-settings:
      'rlig' 1,
      'calt' 1;
  }

  .container {
    @apply max-sm:px-4;
  }

  /* customize sheet overlay styles */
  .backdrop-blur-sm {
    @apply !bg-secondary !opacity-25 !backdrop-blur-none;
  }
}
